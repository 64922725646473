<!--  -->
<template>
    <div class="bod">
    <Breadcrumb style="margin-top: 20px;margin-left: 163px;margin-bottom: 20px;color: rgb(110, 114, 117);">
        <BreadcrumbItem to="/">首页</BreadcrumbItem>
        <BreadcrumbItem to="/message">相关资讯</BreadcrumbItem>
        <BreadcrumbItem>详情</BreadcrumbItem>
    </Breadcrumb>
      <div class="flex">
        <div style="width: 850px;" class="biaoti">
            区妇联王主席视察朝九晚四，推进妇女灵活就业工作
        </div>
      </div>
      <div class="flex">
      <div class="xgleft3"></div>
        <div class="xgleft4">各界关怀</div>
        <div class="xgleft1">发布时间：&nbsp&nbsp2023.02.07</div>
      </div>
      <div class="flex">
        <div class="wenben1" style="margin-bottom: 0;">
            2023年2月7日上午，黄埔区妇联主席王晓红带队莅临朝九晚四创客中心，指导朝九晚四妇女灵活就业工作。
        </div>
      </div>
      <div class="flex">
        <div class="page4tu1"></div>
      </div>
      <div class="flex">
        <div style="margin-bottom: 50px;" class="wenben2">        中心理事长王盛虎先生对朝九晚四2023年宏“兔”计划做了详细介绍。王晓红主席充分肯定朝九晚四在妇女灵活就业方面的成果，希望2023年能大力推进落实工作计划，开发朝九晚四灵活就业岗位，培育百个育儿妈妈，以就业促创业，促进妇女群体灵活增收。</div>
      </div>
      <div class="flex">
        <div class="page4tu2"></div>
      </div>
      <div class="flex">
        <div style="margin-left: 200px;" class="wenben3">                新的一年，中心将全力以赴建设独具大湾区特色的育儿妈妈945灵活就业用工模式。打开新格局，推进新篇章，助力区内妇女灵活就业，增加收入，实现自我，融入社会。</div>
      </div>
     
      <div class="flex">
        <hr class="hr1"/>
      </div>
      <div style="margin-left: 163px;float: left;">
        <div class="next">上一篇</div>
        <div class="nextkuang">
          <div class="xgleft1">2023.05.17</div>
          <router-link to="/page3" class="text1">【区创投项目】“促进就业，宝妈创业”线下宣传招募活动</router-link>
          <div class="xgleft3"></div>
          <div class="xgleft4">就业创业</div>
          <router-link to="/page3" class="text2">让更多妈妈了解朝九晚四“促进就业，宝妈创业”巾帼妈妈灵活创业互助项目，让有意愿灵活创业增收的妈妈有渠道实现自己的想法，助力妈妈步入社会，提升自我。5月16日，朝九晚四巾帼创客中心工作人员在新东党建文化广场开展宣传活动......</router-link>
          <router-link to="/page3"><div class="text3">查看详情<img src="../../../assets/images/you.png"></div></router-link>
        </div>
      </div>
      <div style="margin-left: 75px;float: left;">
        <div class="next">下一篇</div>
        <div class="nextkuang">
          <div class="xgleft1">2022.09.06</div>
          <router-link to="/page5" class="text1">黄埔区妇女就业创业技能培训顺利开展</router-link>
          <div class="xgleft3"></div>
          <div class="xgleft4">就业创业</div>
          <router-link to="/page5" class="text2">为了提升黄埔区妇女就业创业能力，推动妇女融入社会，实现灵活就业创业，激发我区广大妇女群众积极投身经济社会高质量发展，9月6日上午，在区妇联主办和支持下黄埔区朝九晚四巾帼创客服务中心在萝岗街党群服务中心开展妇女就业创业技能培训，来自萝岗街、联和街、长岭街、云埔街的40多名妇女参加本次培训班......</router-link>
          <router-link to="/page5"><div class="text3">查看详情<img src="../../../assets/images/you.png"></div></router-link>
        </div>
      </div>
      <div class="clean"></div>
    </div>
  
  </template>
  
  <script>
  export default {
    data() {
      return {
  
      }
    },
    methods: {
  
    },
    created() {
  
    }
  }
  </script>
  
  <style lang="less" scoped>
  @import "./page.less";
  </style>
  